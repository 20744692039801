import React from "react";

import { searchBar, searchBar__grey, searchBar__white, searchIcon } from "./searchBar.module.css" 
import searchIconImg from "images/search-icon.svg"

const validColours = ["white", "light-grey"];

const sanitize = prop => (prop ? prop.toLowerCase() : "");
const selectValid = (prop, validArr, fallBack) =>
    validArr.includes(prop) ? prop : fallBack;

    

function SearchBar({ backgroundColor, className, ...props }) {
    const SelectedColour = selectValid(sanitize(backgroundColor), validColours, "white");
    const classes = [
        ...(className ? [className] : []), 
        searchBar, (SelectedColour === "white") ? searchBar__white : searchBar__grey
    ]
    
    
    return (
        <form action="#" {...props}>
            <div>
                <input className={classes.join(' ')} placeholder="Search products"/>
                <img className={searchIcon} src={searchIconImg} alt="Search icon"/>
            </div>
        </form>
    );
}

export default SearchBar
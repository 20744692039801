import React, { useState, useRef, useEffect } from "react";
import {Link} from "gatsby"

import { navItem, arrow, dropDown, navSection, up } from "./navItem.module.css" 
import downwardArrow from "images/downward-arrow.svg"

function NavItem({ children, items, to }) {
    const node = useRef();
    const [isOpened, setIsOpened] = useState(false);
    
    const handleClick = e => {
        if (node.current.contains(e.target)) {
          return;
        }
        setIsOpened(false);
    };

    const dropDownItems = items.map(item => {
        return (
            <li>
                { 
                    to ? 
                    (<Link to={`${to}/${item.toLowerCase()}`}>
                            {item}
                    </Link>)
                    :
                    item
                }
            </li>
        );
    })

    useEffect(() => {
        document.addEventListener("mouseover", handleClick);

        return () => {
            document.removeEventListener("mouseover", handleClick);
        };
    }, []);

    let arrowClasses = [arrow];
    if (isOpened) arrowClasses.push(up);
    
    return (
        <div ref={node} className={navSection}>   
            <div
                className={navItem}
                onMouseEnter={() => {
                    setIsOpened(true);
                }}  
            >
                <p>
                    { 
                        to ? 
                        (<Link to={to}>
                            {children}
                        </Link>)
                        :
                        children
                    }
                    <img className={ arrowClasses.join(' ')  } src={ downwardArrow } alt="Arrow"/>
                </p>
            </div>
            

            {isOpened && 
                <div className={dropDown}>
                    <ul>
                        {dropDownItems}
                    </ul>
                </div>
            }
        </div>
        
    );
}

NavItem.defaultProps = {
    to: null,
}

export default NavItem
import React from "react";
import {Link} from "gatsby"
import Logo from 'components/Logo'

import { footer, footerBody, footerLogo, link, links, copyright } from "./footer.module.css"

function Footer() {
    return (
        <footer className={footer}> 
            <Logo className={footerLogo}/>           
            <div className={footerBody}>
                <div>
                    <div className={copyright}>
                        <p>© 2021 Konungr. All rights reserved.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictum lacus pulvinar tincidunt blandit nullam at quam. Vulputate in amet, neque, rhoncus. Imperdiet tincidunt elementum mauris maecenas arcu magna posuere.</p>
                        <a href='/'>Read more</a>
                    </div>
                </div>

                <div>
                    <div className={links}>
                        <Link to='/about-us' className={link}>About Us</Link>
                        <a href='/' className={link}>Shipping</a>
                        <a href='/' className={link}>Instagram</a>
                        <Link to='/faqs' className={link}>FAQs</Link>
                        <a href='/' className={link}>Returns</a>
                        <a href='/' className={link}>Facebook</a>
                        <Link to='/careers' className={link}>Careers</Link>
                        <a href='/' className={link}>Size Guide</a>
                        <a href='/' className={link}>YouTube</a>
                        <Link to='/contact' className={link}>Contact Us</Link>
                        <a href='/' className={link}>Terms &#38; Policies</a>
                        <a href='/' className={link}>Linkedin</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer
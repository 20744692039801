import React from 'react'
import { Link } from 'gatsby'

import logo from 'images/konungr.svg'

function Logo({...props}) {
    return (
        <div {...props}>
            <Link to="/">
                <img src={logo} alt="Konungr Logo"/>
            </Link>
        </div>
    )
}

export default Logo
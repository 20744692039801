import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import MobileNavMenuItem from 'components/MobileNavMenuItem'
import SearchBar from 'components/SearchBar'
import { distinctProductTypesArray } from "utils/distinct-array"

import { searchBar, mobileNavMenu, listItem, open } from './mobileNavMenu.module.css'

function MobileNavMenu( { opened } ) {
    let classes = [mobileNavMenu]
    if (opened) {classes.push(open)}

    const data = useStaticQuery(graphql`
    query MobileNavQuery {
        collections: allShopifyCollection {
            edges {
                node {
                    title
                    handle
                }
            }
        }
        productTypes: allShopifyProduct {
            edges{
                node{
                    productType
                }
            }
        }
    }
  `)

    const { collections, productTypes } = data;
    const productTypesDistinct = distinctProductTypesArray(productTypes);
    const categoriesJSX = collections.edges.map(({node}) => {
        return (
            <li className={ listItem } key={node.handle}>
                <MobileNavMenuItem to={`/products/${node.handle}`} items={productTypesDistinct}>{node.title}</MobileNavMenuItem>
            </li>
        );
    });

    return (
        <div className={classes.join(' ')}>
            <div className={searchBar}>
                <SearchBar backgroundColor="light-grey"/>
            </div>
            
            <ul>
                {categoriesJSX}
            </ul>
        </div>
    )
}

export default MobileNavMenu
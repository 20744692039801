import React, { useState, useRef, useEffect } from 'react'
import Heading from 'components/Heading'
import { Link } from "gatsby"

import { mobileItem, mobileItemText, categoryItems, orangeArrow } from './mobileNavMenuItem.module.css'


function MobileNavMenuItem({to, children, items}) {
    const node = useRef();
    const [isOpened, setIsOpened] = useState(false);
    const toggleArrowStyle = {
        transform: `rotate(90deg)`,
        transition: `transform 0.3s`
    }

    const handleClick = e => {
        if (node.current.contains(e.target)) {
          return;
        }
        setIsOpened(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const dropDownItems = items.map(item => {
        return (
            <li>
                <Link to={`${to}/${item.toLowerCase()}`}>
                    {item}
                </Link>
            </li>
        );
    })

    return (
        <div ref={node}>
            <div 
                onClick={() => {
                    setIsOpened(!isOpened);
                }}
                className={mobileItem}
            >
                <div  
                    className={mobileItemText}
                >
                    <Link to={to}>
                        <Heading level="h4">{children} </Heading>
                    </Link>
                </div>

                <div 
                    className={orangeArrow}
                    style={isOpened ? toggleArrowStyle : null}
                >
                    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.884168 10.4582C0.420779 10.0648 0.420394 9.34978 0.88336 8.95583L4.04964 6.26159C4.5189 5.86229 4.5189 5.13771 4.04964 4.73841L0.883359 2.04416C0.420393 1.65022 0.420778 0.935232 0.884168 0.541785C1.25219 0.229315 1.79235 0.229315 2.16037 0.541785L7.1022 4.73771C7.57255 5.13707 7.57255 5.86293 7.1022 6.26229L2.16037 10.4582C1.79235 10.7707 1.25219 10.7707 0.884168 10.4582Z" fill="var(--seasonal-color)"/>
                    </svg>
                </div>
            </div>
            
            {isOpened && <ul className={categoryItems}>{dropDownItems}</ul>}
        </div>
    );
}

export default MobileNavMenuItem
import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import NavItem from "components/NavItem"
import { distinctProductTypesArray } from "utils/distinct-array"

import { navMenu, listItem } from "./navMenu.module.css" 

function NavMenu({...props}) {

    const data = useStaticQuery(graphql`
    query NavQuery {
        collections: allShopifyCollection {
            edges {
                node {
                    title
                    handle
                }
            }
        }
        productTypes: allShopifyProduct {
            edges{
                node{
                    productType
                }
            }
        }
    }
  `)

    const { collections, productTypes } = data;
    const productTypesDistinct = distinctProductTypesArray(productTypes);

    const categoriesJSX = collections.edges.map(({node}) => {
        return (
            <li className={ listItem } key={node.handle}>
                <NavItem to={`/products/${node.handle}`} items={productTypesDistinct}>{node.title}</NavItem>
            </li>
        );
    });

    return (
        <div {...props}>
            <nav className={ navMenu } >
            <ul>
                { categoriesJSX }
            </ul>
            </nav>
        </div>
    )
}

export default NavMenu
import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import Logo from "components/Logo"
import NavMenu from "components/NavMenu"
import SearchBar from "components/SearchBar"
import MobileNavMenu from "components/MobileNavMenu"
import { StoreContext } from "context/store-context"

import { header, basket, logo, navMenu, searchBar, icons, navBar, basketQuantity } from "./header.module.scss"

const useQuantity = () => {
    const { checkout } = useContext(StoreContext)
    const items = checkout ? checkout.lineItems : []
    const total = items.reduce((acc, item) => acc + item.quantity, 0)
    return [total !== 0, total]
  }

function Header() {
    const [isOpened, setIsOpened] = useState(false);
    const [hasItems, quantity] = useQuantity()
    const isMobile  = useMediaQuery({  maxWidth: 1220 })

    return (
        <header className={header}>

            <div className={navBar}>
                <Logo className={logo} />
                {!isMobile && <NavMenu className={navMenu} />}
                {/* {!isMobile && <SearchBar className={searchBar} /> } */}

                <div className={icons}>
                    <Link className={basket} to="/basket">
                        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.21 6.98736L11.83 0.420443C11.64 0.140148 11.32 0 11 0C10.68 0 10.36 0.140148 10.17 0.430453L5.79 6.98736H1C0.45 6.98736 0 7.43783 0 7.98841C0 8.0785 0.00999996 8.1686 0.04 8.25869L2.58 17.5385C2.81 18.3793 3.58 19 4.5 19H17.5C18.42 19 19.19 18.3793 19.43 17.5385L21.97 8.25869L22 7.98841C22 7.43783 21.55 6.98736 21 6.98736H16.21ZM8 6.98736L11 2.58272L14 6.98736H8ZM11 14.9958C9.9 14.9958 9 14.0948 9 12.9937C9 11.8925 9.9 10.9916 11 10.9916C12.1 10.9916 13 11.8925 13 12.9937C13 14.0948 12.1 14.9958 11 14.9958Z" fill="#2F3542"/>
                        </svg>

                        {hasItems && <span className={basketQuantity}>{quantity}</span>}
                    </Link>

                    {isMobile && <button
                        onClick={() => setIsOpened(!isOpened)}
                        tabIndex="0"
                        aria-pressed={isOpened}
                        aria-expanded={isOpened}
                        title="Open Navigation"
                        className={
                            (isOpened ? "open" : "") + " mobilenav-button"
                        }
                    >
                        <span></span>
                    </button> }
                </div>
            </div>

            {isMobile && <MobileNavMenu opened={isOpened}/>}
        </header>
    );
}

export default Header